<!--钥匙完善率-->
<template>
  <div class="rate_page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div
          class="li"
          @click="changeType(item.type)"
          :class="{ active: item.type == from.type }"
          v-for="(item, index) in headList"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="rate_total">
        钥匙当前数量：<span class="color_blue">{{ amount }}</span>
      </div>
    </div>
    <!--rate_page_head end-->

    <div class="flexs build-head">
      <div class="head-li w-160">
        <el-select
          v-model="from.name"
          filterable
          remote
          clearable
          :remote-method="remoteMethod"
          placeholder="请选择"
        >
          <el-option
            v-for="item in plotList"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div class="head-line"></div>
      <div class="head-li w-68">
        <el-input v-model="from.dongs" placeholder="楼号"></el-input>
      </div>
      <div class="head-tip">楼号</div>
      <div class="head-line"></div>
      <div class="head-li w-68">
        <el-input v-model="from.danyuans" placeholder="单元"></el-input>
      </div>
      <div class="head-tip">单元</div>
      <div class="head-line"></div>
      <div class="head-li w-68">
        <el-input v-model="from.menpais" placeholder="门牌号"></el-input>
      </div>
      <div class="head-tip m-r-40">室</div>
      <div class="head-li">
        <el-button class="build-head-btn" @click="init">搜索</el-button>
      </div>
    </div>

    <!--rate_page_list start-->
    <div class="rate_page_list">
      <ul class="personage_ul">
        <li v-for="(item, index) in list" :key="index">
          <div class="personage_ul_img m-r-20">
            <img :src="item.fengmian_image" alt="" />
            <div class="personage_img_marking">{{ item.fang_level }}</div>
          </div>
          <div class="personage_ul_main f-1">
            <!-- xxx小区x楼-x单元-x楼层-x房号   xx-x-xx-xxxx -->
            <div class="personage_ul_name">
              {{ item.name }}小区{{ item.dongs }}号楼-{{ item.danyuans }}单元-{{
                item.xiaoqu_louceng
              }}楼层-{{ item.menpais }}房号 {{ item.id }}
            </div>
            <div class="personage_ul_title">
              {{ item.fang_xing }} | {{ item.fang_mianji }}m²
            </div>
            <!-- | 8次 | 2天 | 王珊珊 -->
            <div class="personage_ul_tags flex-wrap">
              <span
                class="tag"
                v-for="item in item.fang_biaoqian"
                :key="item"
                >{{ item }}</span
              >
            </div>
            <div class="personage_ul_price flex-between">
              <div class="flex">
                <div class="personage_ul_price_left m-r-20">
                  <span
                    >{{
                      item.fang_type == 1
                        ? item.zong_price
                        : item.can_price || 0
                    }}{{ item.fang_type == 1 ? "万" : "元" }}</span
                  >
                  <!-- <img src="../../assets/image/personage/up2.png" alt="" /> -->
                </div>
                <div
                  class="personage_ul_price_right"
                  v-if="item.fang_type == 1"
                >
                  <span>{{ item.can_price }}元/m²</span>
                  <!-- <img src="../../assets/image/personage/up1.png" alt="" /> -->
                </div>
              </div>

              <div class="personage_ul-bot">
                <el-tag>{{ item.yaoshi_status == 0 ? "在店" : "借出" }}</el-tag>
                <button
                  class="rate_page_btn m-l-20"
                  @click="$refs.record.open(item.id, item.fang_type)"
                >
                  借还记录
                </button>
                <button
                  v-if="userInfo.position != 4"
                  class="rate_page_btn blue m-l-20"
                  :class="{ orange: item.yaoshi_status == 0 }"
                  @click="
                    item.yaoshi_status == 0
                      ? $refs.borrow.open(item.id, item.fang_type)
                      : $refs.return.open(item.id, item.fang_type, item.list_id)
                  "
                >
                  {{ item.yaoshi_status == 0 ? "借钥匙" : "还钥匙" }}
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="paging" v-if="list.length">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--rate_page_list end-->
    <!-- 借钥匙弹框 S -->
    <borrow-pop ref="borrow" @succeed="changeBorrow"></borrow-pop>
    <!-- 借钥匙弹框 E -->
    <!-- 还钥匙弹框 S -->
    <return-pop ref="return" @succeed="changeBorrow"></return-pop>
    <!-- 还钥匙弹框 E -->
    <!-- 借还记录弹框 S -->
    <record-pop ref="record"></record-pop>
    <!-- 借还记录弹框 E -->
    <!-- 借还记录未还清弹框 S -->
    <record-popn ref="recordn"></record-popn>
    <!-- 借还记录未还清弹框 E -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BorrowPop from "../../components/BorrowPop";
import ReturnPop from "../../components/ReturnPop";
import RecordPop from "../../components/RecordPop";
import RecordPopn from "../../components/RecordPopn";
// import 'swiper/css/swiper.css'
export default {
  components: { BorrowPop, ReturnPop, RecordPop, RecordPopn },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      amount: 0, //钥匙数量
      total: 0, //条数
      list: [], //列表
      headList: [
        {
          name: "全部",
          type: 10
        },
        {
          name: "钥匙借出",
          type: 1
        },
        {
          name: "钥匙在店",
          type: 0
        },
      ],
      from: {
        type: 10,
        page: 1,
        pageNum: 10,
        name:'',
        dongs:'',
        danyuans:'',
        menpais:''
      },
      plotList:[]
    };
  },
  methods: {
    remoteMethod(e) {
      this.$axios
        .xiaoQuList({
          letter: e,
          pageNum: 10000,
        })
        .then((res) => {
          this.plotList = this.getFTPrivilegeXiaoQuList(res.data.data);
        });
    },
    changePage(e) {
      this.from.page = e;
      this.getkeyList();
    },
    //切换头部
    changeType(type) {
      this.from.type = type;
      this.init();
    },
    //借钥匙成功
    changeBorrow() {
      this.init();
    },
    init() {
      this.total = 0;
      this.from.page = 1;
      this.list = [];
      this.getkeyList();
    },
    getkeyList() {
      this.$axios.keyList(this.from).then((res) => {
        this.amount = res.data.count;
        this.list = res.data.data.data;
        this.total = res.data.data.total;
      });
    },
  },
  created() {
    this.remoteMethod("");
    this.getkeyList();
  },
};
</script>

<style lang="less" scoped>
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  height: 64px;
  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }
    .active {
      color: #3273f6;
    }
    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
  .rate_total {
    font-size: 18px;
  }
}
.rate_page_list {
  background: #fff;
  padding: 0 50px;
}
.build-head {
  margin: 10px 0;
  .w-120 {
    width: 120px;
  }
  .w-160 {
    width: 160px;
  }
  .w-68 {
    width: 68px;
  }
  .head-line {
    width: 20px;
    height: 2px;
    background: #ccc;
    margin: 0 6px;
  }
  .head-tip {
    font-size: 18px;
    margin-left: 5px;
  }
  /deep/ .el-input__inner {
    height: 48px;
    line-height: 48px;
    font-size: 18px;
  }
  .build-head-btn {
    width: 100px;
    height: 48px;
    background: #3273f6;
    font-size: 18px;
    color: #fff;
  }
}
.personage_ul_tags {
  display: flex;
  margin: 0 -5px;
  .tag {
    background: #f5f5f5;
    height: 28px;
    border-radius: 3px;
    padding: 0 20px;
    margin: 5px;
    font-size: 14px;
    color: #999;
    line-height: 28px;
  }
}
.rate_page_btn {
  width: 112px;
  height: 40px;
  border: 1px solid #3273f6;
  border-radius: 4px;
  color: #3273f6;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  outline: none;
  cursor: pointer;
}

.rate_page_btn.blue {
  background: #3273f6;
  border: 1px solid #3273f6;
  color: #fff;
}
.rate_page_btn.orange {
  background: #ffa740;
  border: 1px solid #ffa740;
  color: #fff;
}
.personage_ul {
  li {
    padding: 40px 0;
    display: flex;
    border-bottom: 1px solid #ebebeb;
    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }
      position: relative;
      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }
    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }
      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }
      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        .personage_ul_price_left {
          display: flex;
          align-items: center;
          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .personage_ul_price_right {
          display: flex;
          align-items: center;
          span {
            color: #999999;
            font-size: 18px;
          }
        }
        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }
      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;
        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }
        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          margin-right: 10px;
        }
        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }
        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
